@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  @apply font-serif text-neutral-950;
}

::-moz-selection {
  background: rgba(178, 131, 83, 0.3);
}

::selection {
  background: rgba(178, 131, 83, 0.3);
}

::-webkit-scrollbar {
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track-piece {
  background-color: #eeeeee;
}

::-webkit-scrollbar-thumb {
  background-color: #171717;
  border: 3px solid #eeeeee;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #B28353;
}

.content-text h1,
.content-text h2,
.content-text h3,
.content-text h4,
.content-text h5,
.content-text h6,
.content-text ul {
  @apply my-6 first:mt-0 last:mb-0;
}

.content-text p {
  @apply mb-3 first:mt-0 last:mb-0;
}

.content-text ul {
  @apply text-sm leading-6 list-disc list-inside;
}

.content-text a {
  @apply font-semibold hover:underline;
}

.content-text table td,
th {
  @apply border border-neutral-100 p-4 sm:p-6;
}

/* .sidebar-menu-item {
  @apply text-base md:text-xl text-white opacity-50 hover:opacity-100 transition;
} */

/* .sidebar-menu-list-gap {
  @apply gap-1 md:gap-2;
} */